import React from 'react'
import { motion } from 'framer-motion'

interface PillProps {
  children: React.ReactNode
}

const Pill: React.FC<PillProps> = ({ children }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
    >
      <div className="group inline-block rounded-full transition-all duration-300 ease-in-out hover:shadow-lg dark:hover:shadow-white/10">
        <h2
          className={
            'inline-flex w-auto items-center space-x-2'
            + ' rounded-full bg-gradient-to-br dark:from-gray-200 dark:via-gray-400'
            + ' dark:to-gray-700 bg-clip-text px-4 py-2 text-center text-sm'
            + ' font-normal text-gray-500 dark:text-transparent shadow'
            + ' dark:shadow-dark-700'
            + ' hover:shadow-blue-400/50'
            + ' dark:hover:shadow-white/10'
          }
        >
          <span>{children}</span>
        </h2>
      </div>
    </motion.div>
  )
}

export default Pill
