import React from 'react'
import { useTheme } from '~/core/contexts/theme-context'

const RadialGradientBackground: React.FC = () => {
  const { isDarkTheme } = useTheme()

  const startColor = isDarkTheme ? '#0284c5' : '#024566'
  const endColor = isDarkTheme ? '#030712' : '#ffffff'

  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      <div
        className="absolute inset-0 opacity-40"
        style={{
          background: `radial-gradient(circle, ${startColor} 0%, ${endColor} 30%)`,
        }}
      >
      </div>
    </div>
  )
}

export default RadialGradientBackground
