import React from 'react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import Button from '~/core/ui/Button'
import { motion } from 'framer-motion'
import classNames from 'clsx'

interface MainCallToActionButtonProps {
  href: string
}

const MainCallToActionButton: React.FC<MainCallToActionButtonProps> = ({ href }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
    >
      <Button
        className={classNames(
          'relative overflow-hidden',
          'bg-gradient-to-r from-sky-500 to-sky-600',
          'text-white shadow-2xl',
          'hover:shadow-primary/60 hover:to-sky-600',
          'transition-all ease-in-out duration-300',
          'fill-mode-both',
        )}
        variant="custom"
        size="lg"
        href={href}
      >
        <span className="flex items-center space-x-2 relative z-10">
          <span>Book a call</span>
          <ChevronRightIcon className="size-4" />
        </span>
        <motion.div
          className="absolute inset-0 bg-gradient-to-r from-transparent via-cyan-100 to-transparent opacity-40"
          animate={{
            x: ['-100%', '100%'],
            skew: ['0deg', '-22deg', '0deg'],
          }}
          transition={{
            repeat: Infinity,
            duration: 4,
            ease: 'linear',
          }}
        />
      </Button>
    </motion.div>
  )
}

export default MainCallToActionButton
