import React from 'react'

interface FeatureItemProps {
  icon: React.ReactNode
  title: string
  description: string
}

const FeatureItem: React.FC<FeatureItemProps> = ({ icon, title, description }) => {
  return (
    <div className="flex flex-col space-y-2">
      <FeatureIcon>{icon}</FeatureIcon>
      <h4 className="text-lg font-semibold text-gray-500 dark:text-gray-100">
        {title}
      </h4>
      <div className="text-sm text-gray-500 dark:text-gray-400">
        {description}
      </div>
    </div>
  )
}

export default FeatureItem

function FeatureIcon(props: React.PropsWithChildren) {
  return (
    <div className="flex">
      <div
        className={
          'rounded-xl bg-primary/5 p-4 dark:bg-background border'
          + ' border-primary/5 dark:border-dark-700'
        }
      >
        {props.children}
      </div>
    </div>
  )
}
