import React from 'react'

interface HeroSubHeadingProps {
  children: React.ReactNode
}

const HeroSubHeading: React.FC<HeroSubHeadingProps> = ({ children }) => {
  return (
    <h2 className="text-center text-lg text-gray-600 dark:text-transparent dark:bg-clip-text dark:bg-gradient-to-br dark:from-gray-400 dark:to-white px-4 max-w-3xl mx-auto">
      {children}
    </h2>
  )
}

export default HeroSubHeading
