import {
  ArrowRightIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'

import {
  Banknote,
  Copy,
  Eclipse,
  History,
  Rocket,
  Tag,
} from 'lucide-react'

import { Link } from '@remix-run/react'
import { useRef } from 'react'

import Heading from '~/core/ui/Heading'
import Divider from '~/core/ui/Divider'
import SubHeading from '~/core/ui/SubHeading'
import Button from '~/core/ui/Button'
import Container from '~/core/ui/Container'

import animationData from '../lottie/landing-hero-new.json'
import { ProgressiveClientOnly } from '~/components/ProgressiveClientOnly'
import { LottiePlayer } from '~/components/LottiePlayer'
import { motion, useInView } from 'framer-motion'

import Pill from '~/components/landingpage/Pill'
import BackedByInfoPill from '~/components/landingpage/BackedByInfoPill'
import HeroSubHeading from '~/components/landingpage/HeroSubHeading'
import FeatureItem from '~/components/landingpage/FeatureItem'
import FeatureBox from '~/components/landingpage/FeatureBox'
import RadialGradientBackground from '~/components/landingpage/RadialGradientBackground'
import EllipseGradientBackground from '~/components/landingpage/EllipseGradientBackground'
import MainCallToActionButton from '~/components/landingpage/MainCallToActionButton'

import CalendlyWidget from '~/components/calendly/Calendly'

export default function Index() {
  return (
    <div className="flex flex-col space-y-16">
      <EllipseGradientBackground />
      <Container>
        <div
          className={
            'my-4 flex flex-col items-center md:flex-row lg:my-6'
            + ' mx-auto flex-1 justify-center animate-in fade-in '
            + ' duration-1000 slide-in-from-top-12'
          }
        >
          <div className="flex w-full flex-1 flex-col items-center space-y-8">
            <BackedByInfoPill href="https://www.joinfounders.co/portfolio" />

            <HeroTitle>
              <span>Say Goodbye to</span>
              <span>Respondent Recruitment</span>
            </HeroTitle>

            <HeroSubHeading>
              Talio creates digital twins of your target respondents based on their survey answers,
              enabling our models to simulate future responses for instant, unlimited questioning
            </HeroSubHeading>

            <div className="flex flex-col items-center space-y-4">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.5, duration: 0.5 }}
              >
                <MainCallToActionButton href="#contact" />
              </motion.div>

              <span className="text-xs text-gray-500 dark:text-gray-400">
                Free trial available. No credit card required.
              </span>
            </div>
          </div>
        </div>

        <div
          className={
            'flex justify-center max-w-5xl mx-auto animate-in fade-in '
            + ' duration-1000 slide-in-from-top-16 fill-mode-both delay-300'
          }
        >
          <ProgressiveClientOnly>
            <LottiePlayer
              animationData={animationData}
              loop={true}
              className={
                'mx-auto h-full max-w-[80%]'
                + ' animate-in fade-in'
                + ' zoom-in-50 delay-300 duration-1000 ease-out fill-mode-both'
              }
            />
          </ProgressiveClientOnly>
        </div>
      </Container>

      <Container>
        <div className="flex flex-col items-center justify-center space-y-24 pb-16 pt-3">
          <div className="flex max-w-3xl flex-col items-center space-y-6 text-center">

            <div className="flex flex-col space-y-2.5">
              <AnimateText>
                <Heading type={2}>The First Ever Augmented Panel</Heading>
              </AnimateText>
              <AnimateText>
                <SubHeading as="h3">
                  Our models use real responses for training, not synthetic ones
                </SubHeading>
              </AnimateText>
            </div>
          </div>

          <div className="px-4 sm:px-0">
            <div className="grid grid-cols-2 gap-y-12 gap-x-16 lg:grid-cols-3">
              <AnimateFeatureItem delay={0.2}>
                <FeatureItem
                  icon={<Copy className="size-5" />}
                  title="Digital Twin"
                  description="Our AI builds 1:1 models of real respondents, capturing their unique thought patterns, preferences, and behaviors."
                  className="sm:text-left text-justify"
                />
              </AnimateFeatureItem>
              <AnimateFeatureItem delay={0.4}>
                <FeatureItem
                  icon={<History className="size-5 -scale-x-100" />}
                  title="Future-Proof"
                  description="By understanding individual respondent tendencies, our models can infer how those respondents might answer future questions."
                  className="sm:text-left text-justify"
                />
              </AnimateFeatureItem>
              <AnimateFeatureItem delay={0.6}>
                <FeatureItem
                  icon={<Eclipse className="size-5" />}
                  title="Augmented vs Synthetic"
                  description="We augment real human data, not generate synthetic responses. This ensures that insights are grounded in real world behavior, giving you results you can trust."
                  className="sm:text-left text-justify"
                />
              </AnimateFeatureItem>
              <AnimateFeatureItem delay={0.8}>
                <FeatureItem
                  icon={<Rocket className="size-5" />}
                  title="Speed"
                  description="Our augmented panellists deliver instant insight rather than waiting days for the real respondent to answer."
                  className="sm:text-left text-justify"
                />
              </AnimateFeatureItem>
              <AnimateFeatureItem delay={1.0}>
                <FeatureItem
                  icon={<Tag className="size-5" />}
                  title="Cost"
                  description="Because you are asking an AI model, the cost per question is significantly cheaper than asking the real person."
                  className="sm:text-left text-justify"
                />
              </AnimateFeatureItem>
              <AnimateFeatureItem delay={1.2}>
                <FeatureItem
                  icon={<Banknote className="size-5" />}
                  title="Unlimited ROI"
                  description="Build your own respondent models and reuse them for multiple studies, generating ongoing returns with each new question asked."
                  className="sm:text-left text-justify"
                />
              </AnimateFeatureItem>
            </div>
          </div>

        </div>
      </Container>

      <Container>
        <div className="flex flex-col items-center justify-center space-y-24 pb-16 pt-3">
          <div className="flex max-w-3xl flex-col items-center space-y-6 text-center">
            <div className="flex flex-col space-y-2.5">
              <AnimateText>
                <Heading type={1}>Innovative Tools for Human and Augmented Research</Heading>
              </AnimateText>
              <AnimateText>
                <SubHeading as="h3">
                  We support both human and augmented data sources for a seamless transition to the future of research
                </SubHeading>
              </AnimateText>
            </div>
          </div>
        </div>
      </Container>

      <div className="relative pb-20">
        <RadialGradientBackground />

        <Container>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 w-full md:max-w-[60vw] mx-auto relative z-10">
            <AnimateFeatureBox direction="left" className="md:col-span-2">
              <FeatureBox
                title="Transcription"
                description="Our AI joins live research calls or processes recordings, delivering instant insights, summaries, and chat-to-transcript analysis."
                image="/assets/images/featurebox-transcribe.png"
                position="topLeft"
                width="full"
              />
            </AnimateFeatureBox>

            <AnimateFeatureBox direction="right">
              <FeatureBox
                title="AI Researcher"
                description="Instantly generate reports on any topic, searching both locally and online, with up-to-date citations for fast, reliable insights."
                image="/assets/images/featurebox-ai-researcher.png"
                position="topRight"
                width="full"
              />
            </AnimateFeatureBox>

            <AnimateFeatureBox direction="left">
              <FeatureBox
                title="Survey Builder"
                description="Use our survey builder to ask your augmented panellists questions about videos, images and much, much more."
                image="/assets/images/featurebox-survey-build.png"
                position="bottomLeft"
                width="full"
              />
            </AnimateFeatureBox>

            <AnimateFeatureBox direction="right" className="md:col-span-2">
              <FeatureBox
                title="AI-Powered Coding"
                description="Accurately categorize responses by topic and sentiment, turning weeks of manual work into minutes for efficient, accurate analysis."
                image="/assets/images/featurebox-topic-analysis.png"
                position="bottomRight"
                width="full"
              />
            </AnimateFeatureBox>
          </div>
        </Container>
      </div>

      <Container>
        <div className="flex flex-col items-center">
          <Link to="#contact">
            <Pill>
              <span className="flex items-center justify-between w-full">
                <span className="flex items-center space-x-2 text-grey-600 dark:text-white">
                  <span>Book a call</span>
                  <ChevronRightIcon className="size-3" />
                </span>
              </span>
            </Pill>
          </Link>
        </div>
      </Container>

      <Divider />
      <Container>
        <div className="py-8 sm:py-8">
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
              <img
                className="absolute inset-0 size-full object-cover brightness-150 saturate-0"
                src="/assets/images/gerard-testimonial.png"
                alt=""
              />
              <div className="absolute inset-0 bg-gray-900/90 mix-blend-multiply" />
              <div
                className="absolute -left-80 -top-56 transform-gpu blur-3xl"
                aria-hidden="true"
              >
                <div className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#A71931] to-[#000] opacity-45" />
              </div>
              <div
                className="hidden md:absolute md:bottom-16 md:left-[50rem] md:block md:transform-gpu md:blur-3xl"
                aria-hidden="true"
              >
                <div
                  className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#A71931] to-[#000]  opacity-25"
                  style={{
                    clipPath:
                      'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                  }}
                />
              </div>
              <div className="relative mx-auto max-w-2xl lg:mx-0">
                <img
                  className="h-20 w-auto"
                  src="/assets/images/amarach-logo.png"
                  alt=""
                />
                <figure>
                  <blockquote className="mt-6 text-lg font-semibold text-white sm:text-xl sm:leading-8">
                    <p>
                      "The tools that have been developed enable us to be much
                      more confident about tightening up turnaround times,
                      committing to faster turnaround than before, but also
                      confident that the quality of the analysis and the insight
                      is second to none."
                    </p>
                  </blockquote>
                  <figcaption className="mt-6 text-base text-white">
                    <div className="font-semibold">Gerard O'Neill</div>
                    <div className="mt-1">Chairman of Amarach</div>
                  </figcaption>
                  <Button
                    className="-ml-4 mt-2 !pl-0 text-white"
                    variant="link"
                    href="blog/case-study-amarach"
                  >
                    Read the full case study
                    {' '}
                    <ArrowRightIcon className="ml-1 size-4" />
                  </Button>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Divider />
      <Container>
        <BackedBy />
      </Container>

      <Divider />

      <div
        className="flex flex-col items-center justify-center space-y-16 py-16"
        id="contact"
      >
        <div className="flex flex-col items-center space-y-6 text-center">
          <Pill>AI powered research at your fingertips</Pill>

          <div className="flex flex-col space-y-2.5">
            <Heading type={2}>
              Ready to take your research to the next level?
            </Heading>

            <SubHeading as="h3">
              Schedule a call with us and let's redefine the future of market
              research together.
            </SubHeading>
            <ProgressiveClientOnly>
              <CalendlyWidget />
            </ProgressiveClientOnly>
          </div>
        </div>
      </div>
    </div>

  )
}

function BackedBy() {
  return (
    <div className="py-12 sm:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg font-semibold leading-8">
          Backed by
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-3 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-3 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <img
            className="col-span-1 max-h-12 w-full object-contain dark:hidden"
            src="/assets/images/dogpatch-black.png"
            alt="dogpatch labs"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 hidden max-h-12 w-full object-contain dark:block"
            src="/assets/images/dogpatch-light.png"
            alt="dogpatch labs"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 max-h-12 w-full object-contain dark:hidden"
            src="/assets/images/ei-black.png"
            alt="Enterprise Ireland"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 hidden max-h-12 w-full object-contain dark:block"
            src="/assets/images/ei-white.png"
            alt="Enterprise Ireland"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 max-h-12 w-full object-contain dark:hidden"
            src="/assets/images/founders-black.png"
            alt="Founders"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 hidden max-h-12 w-full object-contain dark:block"
            src="/assets/images/founders-white.png"
            alt="Founders"
            width={158}
            height={48}
          />
        </div>
      </div>
    </div>
  )
}

function HeroTitle({ children }: React.PropsWithChildren) {
  return (
    <h1
      className={
        'text-center text-5xl text-gray-800 dark:text-transparent dark:bg-clip-text dark:bg-gradient-to-r dark:from-gray-300 dark:to-white md:text-6xl'
        + ' flex flex-col font-heading font-medium xl:text-8xl 2xl:text-[6rem]'
      }
    >
      {children}
    </h1>
  )
}

function AnimateFeatureBox({ direction, children, className }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true, amount: 0.5 })

  return (
    <motion.div
      ref={ref}
      className={className}
      initial={{ opacity: 0, x: direction === 'left' ? -100 : 100 }}
      animate={isInView ? { opacity: 1, x: 0 } : { opacity: 0, x: direction === 'left' ? -100 : 100 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  )
}

function AnimateFeatureItem({ children, delay = 0.5 }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true, amount: 0.3 })

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={isInView ? { opacity: 1 } : { opacity: 0 }}
      transition={{
        duration: 0.8,
        delay,
        ease: 'easeOut',
      }}
    >
      {children}
    </motion.div>
  )
}

function AnimateText({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true, amount: 0.8 })

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: -30 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: -30 }}
      transition={{
        duration: 1.2,
        ease: [0.16, 1, 0.3, 1],
      }}
    >
      {children}
    </motion.div>
  )
}
