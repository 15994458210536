import React from 'react'

interface FeatureBoxProps {
  title: string
  description: string
  image: string
  position: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
}

const FeatureBoxDesktop: React.FC<FeatureBoxProps> = ({ title, description, image, position }) => {
  const getCornerClasses = () => {
    switch (position) {
      case 'topLeft':
        return 'rounded-tl-[2rem] rounded-tr-lg rounded-bl-lg rounded-br-lg'
      case 'topRight':
        return 'rounded-tl-lg rounded-tr-[2rem] rounded-bl-lg rounded-br-lg'
      case 'bottomLeft':
        return 'rounded-tl-lg rounded-tr-lg rounded-bl-[2rem] rounded-br-lg'
      case 'bottomRight':
        return 'rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-[2rem]'
    }
  }

  return (
    <div className={`${getCornerClasses()} overflow-hidden bg-gray-100 dark:bg-gray-800 flex flex-col h-full min-h-[420px]`}>
      <div className="relative grow">
        <img src={image} alt={title} className="w-full object-contain" />
      </div>
      <div className="p-8">
        <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-100 pb-3">
          {title}
        </h4>
        <div className="text-sm text-gray-600 dark:text-gray-500 max-w-[400px]">
          {description}
        </div>
      </div>
    </div>
  )
}

const FeatureBoxMobile: React.FC<FeatureBoxProps> = ({ title, description, image }) => {
  return (
    <div className="rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-800 flex flex-col size-full md:h-[420px]">
      <div className="relative h-[33vh]">
        <img
          src={image}
          alt={title}
          className="size-full object-contain"
        />
      </div>
      <div className="p-6">
        <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-100 pb-3">
          {title}
        </h4>
        <div className="text-sm text-gray-600 dark:text-gray-500">
          {description}
        </div>
      </div>
    </div>
  )
}

const FeatureBox: React.FC<FeatureBoxProps> = (props) => {
  return (
    <>
      <div className="hidden lg:block">
        <FeatureBoxDesktop {...props} />
      </div>
      <div className="lg:hidden">
        <FeatureBoxMobile {...props} />
      </div>
    </>
  )
}

export default FeatureBox
export { FeatureBoxDesktop, FeatureBoxMobile }
