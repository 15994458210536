import { useEffect, useRef, useState } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'

export function LottiePlayer({
  speed = 1,
  loop = false,
  className,
  animationData,
}: {
  animationData: object
  loop?: boolean
  speed?: number
  className?: string
}) {
  const [hasScrolled, setHasScrolled] = useState(false)
  const [isInView, setIsInView] = useState(false)
  const player = useRef<Player>()

  const shouldPlay = isInView && hasScrolled

  useEffect(() => {
    if (shouldPlay) {
      player?.current?.play()
    }
  }, [shouldPlay])

  const elementRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries
      // Here you can check entry.isIntersecting
      if (entry.isIntersecting) {
        setIsInView(true)
      }
    }, {
      /* Options go here, like threshold: [0, 0.5, 1] */
    })

    if (elementRef.current) {
      observer.observe(elementRef.current)
    }

    const handleScroll = () => {
      // Your scroll handling logic
      setHasScrolled(true)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current)
      }
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      ref={elementRef}
      className={className}
    >
      <Player
        ref={player as any}
        autoplay={false}
        loop={loop}
        speed={speed}
        src={animationData}
        style={{ height: 'auto', width: '100%' }}
        keepLastFrame={true}
      />
    </div>
  )
}
