import { useEffect } from 'react'

function CalendlyWidget() {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      // Cleanup the script when the component unmounts
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div className="calendly-inline-widget" data-url="https://calendly.com/oran-talio/talio-introduction?hide_gdpr_banner=1" style={{ minWidth: '320px', height: '930px' }}></div>
  )
}

export default CalendlyWidget
