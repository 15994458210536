import React from 'react'
import { useTheme } from '~/core/contexts/theme-context'

const EllipseGradientBackground: React.FC = () => {
  const { currentTheme } = useTheme()
  const isDark = currentTheme === 'dark'

  const startColor = isDark ? '#0284c5' : '#024566'
  const endColor = isDark ? '#030712' : '#ffffff'

  return (
    <div className="absolute top-0 left-0 size-full overflow-hidden pointer-events-none z-[-1]">
      <div
        className="absolute -left-1/2 -top-1/4 size-full opacity-50"
        style={{
          background: `radial-gradient(ellipse at center, ${startColor} 0%, ${endColor} 50%)`,
          transform: 'rotate(40deg) skew(-25deg)',
        }}
      />
    </div>
  )
}

export default EllipseGradientBackground
